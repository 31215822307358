import axios from "axios";
import authFirebase from "../../authFirebase";
import ENDPOINT_API from "@/logic/globalEndpoints";


export default {
    // Metodo para hacer GET A TODAS LAS APLICACIONES DE UN JOB
    async getAllApplicationJob(id_company, id_job, page, limit) {

        const params = {
            id_company: id_company,
            id_job: id_job,
            page: page,
            limit: limit
        };

        const headers = {
            Authorization: 'Bearer ' + await authFirebase.createTokenJWT()
        };

        const resp = await axios.get(ENDPOINT_API + "b2b/applicationJob", { params , headers})
        return resp;

    },

    //++++++++++++++++ Metodo DELETE para Eliminar todos los aplicantes de un Job +++++++++++++++++++++++++
    async deleteApplicationsJobCompany(id_company, id_job) {
        // const params = {
        //     id_company: id_company,
        //     id_job: id_job,

        // };
        const headers = {
            Authorization: 'Bearer ' + await authFirebase.createTokenJWT(),
            id_company: id_company,
            id_job: id_job,
        }

        const resp = await axios.delete(ENDPOINT_API + "b2b/applicationJob", { headers })
        return resp;
    },

    //++++++++++++++++ Metodo GET para VERIFICAR UNA APLICACION DE UN JOB DUPLICADA +++++++++++++++++++++++++
    async verifyApplicationJob(id_company, id_job, complete_name, whatsapp_number, personal_email, personal_linkedin, personal_website) {

        const params = {
            id_company: id_company,
            id_job: id_job,
            complete_name: complete_name,
            whatsapp_number: whatsapp_number,
            personal_email: personal_email,
            personal_linkedin: personal_linkedin,
            personal_website: personal_website
        };

        const resp = await axios.get(ENDPOINT_API + "b2b/verifyApplicationJob", { params })
        return resp;
    },

    //++++++++++++++++ Metodo POST para CREAR UNA APLICACION DE UN JOB +++++++++++++++++++++++++
    async createApplicationJob(id_company, id_job, complete_name, whatsapp_number, personal_email, personal_cv, personal_linkedin, personal_website) {

        const newApplicationJobModel = {
            id_company: id_company,
            id_job: id_job,
            complete_name: complete_name,
            whatsapp_number: whatsapp_number,
            personal_email: personal_email,
            personal_cv: personal_cv,
            personal_linkedin: personal_linkedin,
            personal_website: personal_website
        };

        const resp = await axios.post(ENDPOINT_API + "b2b/applicationJob", newApplicationJobModel)
        return resp;
    },

    //++++++++++++++++ Metodo POST para CREAR UNA AGREGACION DE UN JOB POR APLICANTE +++++++++++++++++++++++++
    async setAggregationJob(id_company, id_job) {

        const newAggregationJobModel = {
            id_company: id_company,
            id_job: id_job,
        };

        const resp = await axios.post(ENDPOINT_API + "b2b/applicationAggregate", newAggregationJobModel)
        return resp;
    },

    //++++++++++++++++ Metodo UPDATE para ACTUALIZAR TODAS LAS OPORTUNIDADES POR "id_Company" +++++++++++++++++++++++++
    async updateStatusApplication(id_application, status, status_process) {
        const updateSchema = {
            id_application: id_application,
            status: status,
            status_process: status_process

        };
        const headers = {
            headers: { Authorization: 'Bearer ' + await authFirebase.createTokenJWT() }
        };
        const resp = await axios.put(ENDPOINT_API + "b2b/applicationJob", updateSchema, headers)
        return resp;
    },
}