<template>
  <div>

    <div class="hidden-sm-and-down">
    <v-app-bar app color="white" elevation="0" height="75">
      <div style="margin-left: 240px;">
        <a :href="'https://app.pill.com.co/' + idCompany">
          <v-img v-bind:src="urllogo" class="dynamic-image"></v-img>
        </a>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>


    <!-- for mobile-->
    <div class="hidden-md-and-up">
      <v-app-bar app color="white" elevation="0" height="80">

        <a :href="'https://app.pill.com.co/' + idCompany">
          <v-img v-bind:src="urllogo" class="dynamic-image"></v-img>
        </a>

        <v-spacer></v-spacer>

      </v-app-bar>
    </div>
  </div>
</template>


<script>
// ()=>{this.$router.push('/Dashboard/Insights')}
export default {
  name: 'techoportunities-',
  props: {
    urllogo: { type: String, required: true },
    idCompany: { type: String, required: true }
  },
  created() {
    // console.log("crear header")
  },
  data() {
    return {
      // your data properties here
    }
  },
  methods: {


  },
}

</script>




<style>
.dynamic-image {
  max-width: 75px; /* Cambia este valor según tus preferencias */
  max-height: 75px; /* Cambia este valor según tus preferencias */
  width: auto;
  height: auto;
}
</style>
