<template>
    <!-- If using vue-router -->
    <router-view>
      <router-view />
      <notifications group="foo" />
    </router-view>
</template>
<script>
export default {
  name: 'App'
}
</script>
