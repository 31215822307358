/* ---------------- Nombre del Endpoint Global que utilizará el Frontend ---------------------------- */

/*
PRODUCCION */

const ENDPOINT_API = "https://pill-main-prod-backend-ebaaxsuvaa-uc.a.run.app/api/"

/*
DEV CLOUD */

// const ENDPOINT_API = "https://pill-main-dev-backend-ebaaxsuvaa-uc.a.run.app/api/"

/*
DESARROLLO LOCAL */
// const ENDPOINT_API = "http://localhost:3005/api/"




module.exports = ENDPOINT_API