<template>
  <v-app>
        <v-col>
          <div style="padding: 80px 0px 0px">
          <router-link to="/">
            <v-img class="mx-auto" src='https://d1kz8c63g8kbpa.cloudfront.net/web/DashboardLogo.png' max-height="80" max-width="80"
            contain></v-img>
          <br>
          </router-link>

          <v-card class="rounded-lg elevation-0 mx-auto mx-2" max-width="500">
            <center>
              <br>
              <h2 style="font-size: 22px;" class="ml-8 mb-1 mr-8">Recupera tu contraseña.</h2>
              <p style="font-size: 13px;"  class="ml-8 mb-6 mr-8">Si encontramos una cuenta asociada, te enviaremos un enlace de recuperación.</p>
            </center>
            <v-card-text>
              <form ref="form" @submit.prevent="resetPassword()" class="mx-3">

                <p class="ml-5" style="color:rgb(24, 24, 24); font-weight: 500;">Email</p>
                <v-text-field :rules="emailRules" v-model="emailRecovery" style="border-radius: 10px" type="email" hint="Email"
                required solo outlined dense color="#7579e7"></v-text-field>
                <center>
                  <v-btn  type="submit" class="white--text mt-4 mb-4" :loading="loadingRecovery" :disabled="loadingRecovery" rounded color="#7579E7">Recuperar contraseña</v-btn>
                </center>
              </form>
            </v-card-text>
          </v-card>
          <br>
          <center>
            <br>
            <a style="color:black"></a><a v-on:click="goToLogin()" style="text-decoration: underline;">Log in</a>
          </center>
          <br />
          </div>
        </v-col>
  </v-app>
</template>


<script>
// import alerts from "@/logic/alerts"; // Importar libreria donde estan todos los metodos auht Firebase

import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import authFirebase from "@/logic/authFirebase"; // Importar libreria donde estan todos los metodos auht Firebase

Vue.use(VueToast);


export default {
  name: "Login-",
  data: () => ({
    emailRecovery: "",
    loadingRecovery: false,
  }),
  methods: {
    goToLogin() {
      this.$router.push("/login")
    },

    async resetPassword() {
      this.loadingRecovery = true; //activa el loader
      console.log(this.emailRecovery)

      const response = await authFirebase.sendPasswordResetEmail(this.emailRecovery)
      //console.log(response)

      if (response == "auth/user-not-found") {
        Vue.$toast.open({
            message: '<p style="font-family: roboto;">Usuario NO encontrado!</p>',
            type: 'error',
            position: 'top',
            duration: 7000
        });
        // console.log("ERROR USUARIO NO ENCONTRADO");
        this.loadingRecovery = false; //desactiva el loader
      }
      else {
        Vue.$toast.open({
            message: '<p style="font-family: roboto;">¡Te hemos enviado un enlace para restablecer la contraseña!</p>',
            type: 'success',
            position: 'top',
            duration: 7000
        });
        this.loadingRecovery = false; //desactiva el loader
        this.emailRecovery = ""
      }
      
    },
    
  },
};
</script>

<style>
.v-btn {
  text-transform: none;
}

.swal2-popup {
  font-family: 'roboto' !important;
}
</style>