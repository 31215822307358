import axios from "axios";
// import authFirebase from "../../authFirebase";
import ENDPOINT_API from "@/logic/globalEndpoints";


export default {

    async uploadFileS3(formData, id_company, folder_name, file_name) {
        const params = {
            id_company: id_company,
            folder_name: folder_name,
            file_name: file_name
        };
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        const resp = await axios.post(ENDPOINT_API + "upload", formData, { params }, headers)
        return resp;

    },

    async uploadLogoS3(formData, id_company, folder_name) {
        // const params = {
        //     id_company: id_company,
        //     folder_name: folder_name
        // };
        const headers = {
            'Content-Type': 'multipart/form-data',
            // Authorization: 'Bearer ' + await authFirebase.createTokenJWT(),
            id_company: id_company,
            folder_name: folder_name
        }
        const resp = await axios.post(ENDPOINT_API + "uploadLogo", formData, { headers })
        return resp;

    },


}