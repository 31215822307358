import axios from "axios";
import authFirebase from "../../authFirebase";
import ENDPOINT_API from "@/logic/globalEndpoints";


export default {

    //++++++++++++++++ Metodo POST para CREAR UNA COMPANY +++++++++++++++++++++++++
    async createCompany(id_company, name_company, email_company, industry, headquaters_company, website_company, linkedin_company, instagram_company, url_logo_company, color_company) {

        const params = {
            id_company: id_company,
            name_company: name_company,
            email_company: email_company,
            industry: industry,
            headquaters_company: headquaters_company,
            website_company: website_company,
            linkedin_company: linkedin_company,
            instagram_company: instagram_company,
            url_logo_company: url_logo_company,
            color_company: color_company
        };

        const resp = await axios.post(ENDPOINT_API + "b2b/registerCompany", params)
        return resp;
    },

    //++++++++++++++++ Metodo UPDATE para ACTUALIZAR LOS DATOS POR "id_Company" +++++++++++++++++++++++++
    async updateDataCompany(id_company, name_company, headquaters_company, website_company, linkedin_company, instagram_company) {

        const params = {
            id_company: id_company,
            name_company: name_company,
            headquaters_company: headquaters_company,
            website_company: website_company,
            linkedin_company: linkedin_company,
            instagram_company: instagram_company,
        }

        const headers = {
            headers: { Authorization: 'Bearer ' + await authFirebase.createTokenJWT() }
        };
        const resp = await axios.put(ENDPOINT_API + "b2b/updateCompany", params, headers)
        return resp;
    },

    //++++++++++++++++ Metodo UPDATE para ACTUALIZAR Description "id_Company" +++++++++++++++++++++++++
    async updateDescriptionCompany(id_company, description_company) {

        const params = {
            id_company: id_company,
            description_company: description_company
        }

        const headers = {
            headers: { Authorization: 'Bearer ' + await authFirebase.createTokenJWT() }
        };
        const resp = await axios.put(ENDPOINT_API + "b2b/updateDescriptionCompany", params, headers)
        return resp;
    },

    // Metodo para hacer GET A LA INFO DE CADA COMPANY PUBLIC
    async getDataCompany(id_company) {

        const params = {
            id_company: id_company
        };

        const headers = {
            // Authorization: 'Bearer ' + await authFirebase.createTokenJWT(),
            // id_company: id_company
        };

        const resp = await axios.get(ENDPOINT_API + "b2b/company", { params }, headers)
        return resp;

    },

    // Metodo para hacer GET A LA INFO DE CADA COMPANY PRIVATE
    async getDataPrivateCompany(id_company) {

        const params = {
            id_company: id_company
        };

        const headers = {
            // Authorization: 'Bearer ' + await authFirebase.createTokenJWT(),
            // id_company: id_company
        };

        const resp = await axios.get(ENDPOINT_API + "b2b/companyPrivate", { params }, headers)
        return resp;

    }

}

